._404 {
	min-height: 80vh;
	position: relative;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: $white;
	padding: 110px 0 35px;

	&__container {
		max-width: 500px;
		width: 100%;
		padding: 20px 0;
		margin: 0 auto;
		text-align: center; }

	&__title,
	&__descr {
		@include bold;
		p {
			line-height: 1;
			font-size: inherit;
			color: inherit;
			font-family: inherit;
			font-weight: inherit; } }

	&__title {
		font-size: 140px;
		color: rgba($grey, .35); }

	&__descr {
		margin: 0 0 10vh; } }
